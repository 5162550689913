import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr.js'
import { english } from 'flatpickr/dist/l10n/default.js'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    let locale = this.element.getAttribute('data-locale')
    locale = locale == 'en' ? english : Greek

    this.config = {
      monthSelectorType: 'static',
      mode: 'range',
      dateFormat: 'd-m-Y',
      locale: locale
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change (selectedDates, dateStr, instance) {
    const myData = {
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      filter: 'by_week'
    }

    if (selectedDates[1] != null && selectedDates[0] != null) {
      const updateChartID = this.inputTarget.getAttribute('data-chart-id')

      if (updateChartID != null) {
        const chart = Chartkick.charts[updateChartID]
        if (chart != null) {
          const locationNodePathName = chart.getDataSource().split('?')[0]
          chart.updateData(
            locationNodePathName + '?' + new URLSearchParams(myData).toString()
          )
        } else {
          console.log(
            'can not find chart with the spacific id: ' + updateChartID
          )
        }
      } else {
        Chartkick.eachChart(function (chart) {
          const locationNodePathName = chart.getDataSource().split('?')[0]
          chart.updateData(
            locationNodePathName + '?' + new URLSearchParams(myData).toString()
          )
        })
      }
    }
  }
}
