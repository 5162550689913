// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Reveal from 'stimulus-reveal-controller'

const application = Application.start()
application.register('reveal', Reveal)

const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
