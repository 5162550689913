// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// https://medium.com/store2be-tech/how-to-use-sweetalert2-for-your-rails-5-1-rails-ujs-confirms-without-jquery-8a5b516b2a1
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

// vender imports
import 'chartkick/chart.js'
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'

import App from 'src/vendor/oneui/javascript/app'
import 'src/vendor/horizontal_timeline/assets/js/main'

// custom js imports
import 'src/javascripts/user/omniauth_facebook'
import 'src/javascripts/user/turbolinks_prepare_for_cache'
import 'src/javascripts/shared/globals'
import 'src/javascripts/user/sensors'

import 'controllers'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
hljs.registerLanguage('json', json)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = name => images(name, true)

$(document).on('turbolinks:load', function (e) {
  hljs.highlightAll()

  window.OneUIApp = new App()
  $('[data-toggle="tooltip"]').tooltip()
})
