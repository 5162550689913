import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['option', 'input']
  static classes = ['active', 'inactive']

  static values = {
    allowMultiple: Boolean
  }

  connect () {
    this.activeClassList = this.hasActiveClass ? this.activeClass.split(' ') : []
    this.inactiveClassList = this.hasInactiveClass ? this.inactiveClass.split(' ') : []
  }

  selectOption (event) {
    this.optionTargets.forEach((el, i) => {
      if (event.currentTarget === el) {
        this.addClassList(el, this.activeClassList)
        this.removeClassList(el, this.inactiveClassList)
        if (this.hasInputTarget) {
          this.inputTarget.value = el.dataset.optionId
        }
        return
      }

      this.removeClassList(el, this.activeClassList)
      this.addClassList(el, this.inactiveClassList)
    })
  }

  toggleClassList (element, classList) {
    classList.forEach(className => { element.classList.toggle(className) })
  }

  addClassList (element, classList) {
    classList.forEach(className => { element.classList.add(className) })
  }

  removeClassList (element, classList) {
    classList.forEach(className => { element.classList.remove(className) })
  }
}
